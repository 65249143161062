<template>
  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox">
          <div class="ibox-title">
            <h5>{{ title }} User</h5>
          </div>
          <ValidationObserver v-slot="{ invalid }">
            <form @submit.prevent="onSubmit" autocomplete="off">
              <div class="ibox-content">
                <div class="row">
                  <div class="form-group col-md-5">
                    <ValidationProvider
                      name="User Name"
                      :rules="'required|alpha_num'"
                      v-slot="{ errors, classes }"
                    >
                      <label>User Id *</label>
                      <input
                        type="text"
                        placeholder="User Name"
                        v-model="user.userId"
                        class="form-control"
                        id="userId"
                        :class="classes"
                      />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group col-md-5">
                    <label>User Name *</label>
                    <ValidationProvider
                      name="User Name"
                      :rules="{ required: true }"
                      v-slot="{ errors, classes }"
                    >
                      <input
                        type="text"
                        placeholder="First Name"
                        v-model="user.userName"
                        id="userName"
                        class="form-control"
                        :class="classes"
                      />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <div class="form-group col-md-5">
                    <ValidationProvider
                      name="Customer"
                      :rules="{ required: true }"
                      v-slot="{ errors, classes }"
                    >
                      <label>Customer *</label>
                      <div :class="classes">
                        <select
                          class="customer-select form-control"
                          ref="selectedItem"
                          v-model="user.customerId"
                          id="customerId"
                        >
                          <option value="">----- Select -----</option>
                          <option
                            v-for="(value, key) in customerData"
                            :key="key"
                            :value="value.id"
                          >
                            {{ value.text }}
                          </option>
                        </select>
                      </div>
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group col-md-5">
                    <ValidationProvider
                      name="Group"
                      :rules="{ required: true }"
                      v-slot="{ errors, classes }"
                    >
                      <label>Group *</label>
                      <div :class="classes">
                        <select
                          class="group-select form-control"
                          ref="selectedItem"
                          v-model="user.groupId"
                          id="groupId"
                        >
                          <option value="">----- Select -----</option>
                          <option
                            v-for="(value, key) in groupData"
                            :key="key"
                            :value="value.id"
                          >
                            {{ value.text }}
                          </option>
                        </select>
                      </div>
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-4 col-sm-offset-2">
                    <button class="btn btn-white btn-sm" type="button">Clear</button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <button
                      class="btn btn-primary btn-sm ladda-button"
                      data-style="zoom-in"
                      type="submit"
                      :disabled="invalid"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AxiosService from "../plugin/AxiosService.js";
import Constant from "../plugin/Constant.js";
import Utils from "../plugin/Utils";
import AuditListner from "../plugin/AuditListner";

export default {
  data() {
    return {
      user: {
        userId: "",
        userName: "",
        groupId: "",
        customerId: 0,
        roleId: 0,
        id: 0,
      },
      title: Constant.data.ADD_TITLE,
      url: Constant.url.USER_ADD,
      groupData: [],
      customerData: [],
    };
  },
  created: function () {
    this.loadUserData();
  },
  mounted: function () {},
  methods: {
    onSubmit: function () {
      const self = this;
      var groupName = $("#groupId option:selected").text();
      var groupRole = Utils.splitStr(this.user.groupId, ":", 2);
      this.user.groupId = Number(groupRole[1]);
      this.user.roleId = Number(groupRole[0]);
      AxiosService.post(this.url, this.user).then((result) => {
        if (
          Utils.hasValue(result, Constant.data.RESPONSE_CODE) &&
          result.responseCode == Constant.data.SUCCESS
        ) {
          AuditListner.addAuditLog(
            AuditListner.initalizedData(
              Constant.data.USER_ACTIVITY,
              this.title,
              AuditListner.userAuditTemplate('',groupName)
            )
          );
          self.$router.replace(Constant.url.SEPARATER + Constant.url.USER_URI);
        }
      });
    },
    loadUserData: function () {
      const self = this;
      AxiosService.get(Constant.url.GROUP_ROLE_KEY_VALUE).then((result) => {
        self.groupData = result;
      });
      AxiosService.get(Constant.url.CUSTOMER_KEY_VALUE).then((result) => {
        self.customerData = result;
      });
      var groupId = this.$route.query.id;
      if (groupId != undefined) {
        AxiosService.get(Constant.url.USER_GET + groupId).then((result) => {
          self.user = result;
          self.user.groupId = result.roleId + ":" + result.groupId;
        });
        this.title = Constant.data.UPDATE_TITLE;
        this.url = Constant.url.USER_UPDATE;
      }
    },
  },
  watch: {},
};
</script>
<style scoped></style>
